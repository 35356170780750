import React, { useState, useEffect } from 'react';
import Fullscreen from '../../../images/fullscreen.svg';
import ExitFullscreen from '../../../images/exitfullscreen.svg';

export const useFullscreen = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  return { isFullscreen, toggleFullscreen };
};

export  default function FullscreenGeneral  () {
  const { isFullscreen, toggleFullscreen } = useFullscreen();

  return (
    <div>
      <button className="fullscreenBtn" onClick={toggleFullscreen}>
        {isFullscreen ? (
          <>
            <ExitFullscreen />
            <div className="fullscreenTooltip">
              <h3 className="tooltip">Exit Full Screen</h3>
            </div>
          </>
        ) : (
          <>
            <Fullscreen />
            <div className="fullscreenTooltip">
              <h3 className="tooltip">Go Full Screen</h3>
            </div>
          </>
        )}
      </button>
    </div>
  );
};
