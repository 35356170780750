import React from 'react';
import * as styles from './styles.module.scss';
import { useState, useEffect } from 'react';
import { navigate } from 'gatsby';

export default function Explore() {

   const data = [
    {
      tab: 'Company Profile',
      content: [
        {
          title: 'Before/After',
          thumbnail:
            'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1708091194/Rectangle_15021_2_bnda2h.png',
          sourceUrl:
            'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1702779604/Manuals/before_and_after_first_1_-min_gbdj4p.png',
            description: 'Showcasing real-life examples on differences and similarities',
        },
        {
          title: 'Must try features',
          thumbnail:
            'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1708091073/Rectangle_15020_2_ykdq1e.png',
          sourceUrl:
            'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703459181/web%20images/what_we_recommend_zk5ca5.png',
            description: 'Showcasing real-life examples on differences and similarities'
        },
        {
          title: '10 Top Reasons',
          thumbnail:
            'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1708091072/Rectangle_15029_1_kbnyth.png',
          sourceUrl:
            'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703459179/web%20images/TOP_10_REASONS_second_rjxosw.png',
            description: 'Showcasing real-life examples on differences and similarities'
        },
      ]
    },
    {
      tab: 'Info Hub',
      content: [
        {
          title: 'Freelancer',
          thumbnail:
            'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701641634/Rectangle_15020_1_lay3qb.png',
          sourceUrl:
            'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701641943/freelancer_ix6yzb.jpg'
        },
        {
          title: 'NGO',
          thumbnail:
            'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701641688/Rectangle_15021_ggqnjz.png',
          sourceUrl:
            'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701642005/Info_hub_ngo_m2zxgb.png'
        },
        {
          title: 'Startup',
          thumbnail:
            'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701641687/Rectangle_15029_dey6i2.png',
          sourceUrl:
            'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701642079/Info_hub_start_up_kmvmo1.png'
        },
        {
          title: 'Community',
          thumbnail:
            'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701641687/Rectangle_15033_zuwvfi.png',
          sourceUrl:
            'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701642167/online_community_cgerxn.png'
        }
      ]
    },
    {
      tab: 'Individual Profiles',
      content: [
        {
          title: 'Freelancer',
          thumbnail:
            'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701656519/Templates/Rectangle_15020_2_fv08nv.png',
          sourceUrl:
            'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701656705/Templates/creative_x16jkx.png'
        },
        {
          title: 'NGO',
          thumbnail:
            'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701656519/Templates/Rectangle_15021_1_a8l9vd.png',
          sourceUrl:
            'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701656710/Templates/ngo_pcseij.png'
        },
        {
          title: 'Startup',
          thumbnail:
            'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701656519/Templates/Rectangle_15029_1_x8bipm.png',
          sourceUrl:
            'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701656708/Templates/start_up_atbg0e.png'
        },
        {
          title: 'Community',
          thumbnail:
            'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701656519/Templates/Rectangle_15033_1_c4kabw.png',
          sourceUrl:
            'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701656711/Templates/community_kxlikm.png'
        }
      ]
    }
  ];

  const [selectedTab, setSelectedTab] = useState(data[0]);
  const [selectedThumbnail, setSelectedThumbnail] = useState(data[0].content[0]);
  const [scale, setScale] = useState({ number: 60, status: 125 });

  useEffect(() => {
    setTimeout(() => {
      setSelectedTab(data[0]);
      setSelectedThumbnail(data[0].content[0]);
    }, 2000);
  }, []);

  const handleThumbnailClick = (item) => {
    setSelectedThumbnail(item);
  };
  const handleTabClick = (item) => {
    setSelectedTab(item);
    handleThumbnailClick(item.content[0]);
  }
  const handlePlus = () => {
    if (scale.number <= 90) {
      setScale({ number: scale.number + 10, status: scale.status + 25 });
    }
  };
  const handleMinus = () => {
    if (scale.number >= 20) {
      setScale({ number: scale.number - 10, status: scale.status - 25 });
    }
  };


  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {/* <div className={styles.back} onClick={() => navigate(-1)}>
       
          <span>Back</span>
        </div> */}
        <div className={styles.topNav}>
          <div className={styles.buttonsWrapper}>
            {/* <div className={styles.topbtns}>
              {data.map((item) => (
                <button
                  style={{color : selectedTab?.tab === item.tab ? '#00dbab' : 'white', borderBottom: selectedTab?.tab === item.tab ? ' 1px solid #00dbab' : 'none'}}
                  key={item.tab}
                  onClick={() => {
                    handleTabClick(item);
                  }}
                >
                  {item.tab}
                </button>
              ))}
            </div> */}
          </div>
        </div>
        <div className={styles.imageResize}>
          {/* {selectedTab && (
            <>
              <p>{scale.status}%</p>
              <button
                disabled={scale.number <= 20 ? true : false}
                onClick={handleMinus}
                className={styles.minusbtn}
              >
                -
              </button>
              <button disabled={scale.number >= 90 ? true : false} onClick={handlePlus}>
                +
              </button>
            </>
          )} */}
        </div>
        <div className={styles.thumbnails}>
          {selectedTab?.content?.map((item) => (
            <div
              className={
                selectedThumbnail?.sourceUrl === item.sourceUrl
                  ? `${styles.thumbnailGuide} ${styles.selected}`
                  : `${styles.thumbnailGuide}`
              }
              key={item.title}
            >
              <img
                aria-hidden="true"
                src={item.thumbnail}
                alt={item.title}
                loading="lazy"
                onClick={() => handleThumbnailClick(item)}
              />
              <p>{item.title}</p>
              <span>{item.description}</span>
            </div>
          ))}
        </div>
        {selectedTab && (
          <div
            className={styles.imageWrapper}
            style={{
              width: `${scale.number}vw`
            }}
            width={`${scale.number}vw`}
          >
            <img loading="lazy" src={selectedThumbnail?.sourceUrl} alt="thumbnail" />
          </div>
        )}
      </div>
    </div>
  );
        }
    