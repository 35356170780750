// extracted by mini-css-extract-plugin
export var back = "styles-module--back--4f611";
export var buttonsWrapper = "styles-module--buttonsWrapper--ce511";
export var container = "styles-module--container--56586";
export var content = "styles-module--content--6a247";
export var imageResize = "styles-module--imageResize--98c32";
export var imageWrapper = "styles-module--imageWrapper--ecb05";
export var itemWrapper = "styles-module--itemWrapper--dc30d";
export var minusbtn = "styles-module--minusbtn--1a11e";
export var pdfDownload = "styles-module--pdfDownload--80c1b";
export var selected = "styles-module--selected--02859";
export var thumbnailGuide = "styles-module--thumbnailGuide--cba9f";
export var thumbnails = "styles-module--thumbnails--de397";
export var topNav = "styles-module--topNav--712f8";
export var topbtns = "styles-module--topbtns--64159";