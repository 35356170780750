import React from 'react';
import Header from "../components/atoms/header";
import Explore from '../components/ogranisms/Explore/Explore';
import Head from "../components/ogranisms/head";
import FullscreenGeneral from '../components/atoms/Fullscreen/FullscreenGeneral';
import { Helmet } from "react-helmet";

export default function examples() {

  return (
    <div>
      <Helmet>
        <title>Examples</title>
        <link rel="canonical" href="https://masterwizr.com/examples" />
      </Helmet>
      <FullscreenGeneral />
      <Head
        title="Welcome to the WiZR workspace"
        description="Welcome to the WiZR workspace
        The place to organize, present, share and collaborate. Cultivating what matters the most to you."
        ogTitle="About us"
      />
      <Explore />
      <Header />
    </div>
  )
}
